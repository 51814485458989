import type { NextPage } from 'next'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../components/Loading'

const Login: NextPage = () => {
    const { loginWithRedirect } = useAuth0()
    const url = window.location.href
    const inviteMatches = url.match(/invitation=([^&]+)/)
    const orgMatches = url.match(/organization=([^&]+)/)
    if (inviteMatches && orgMatches) {
        loginWithRedirect({
            organization: orgMatches[1],
            invitation: inviteMatches[1],
            screen_hint: 'signup',
            initialScreen: 'signUp',
        })
    } else {
        loginWithRedirect({})
    }

    return (
        <div>
            <Loading />
        </div>
    )
}

export default Login
